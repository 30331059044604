'use strict';

module.exports = function () {
    $('body').on('mouseenter', '.product-tile .swatch', function () {
        var currentSubtitle = $(this).attr('data-subtitle') || '&nbsp;';
        $(this).closest('.product-tile').find('.product-subtitle-description').html(currentSubtitle);
    });
    $('body').on('mouseleave', '.product-tile .swatches', function () {
        var $productTile = $(this).closest('.product-tile');
        var defaultSubtitle = $productTile.find('.tile-image').attr('data-defaultsubtitle') || '&nbsp;';
        $productTile.find('.product-subtitle-description').html(defaultSubtitle);
    });
};
